import IUserList from '../UserList/UserList.interface';
import IOrganizationAdmin from './OrganizationAdmin.interface';

// Helper class to store all the Organization Admin data from db to an array
export const orgUserList = (
  arrAdmin: Array<IOrganizationAdmin | null>
): Array<IUserList | null> => {
  // Declaring the array as of type IUserList or null
  const arrUserList: Array<IUserList | null> = [];
  // Mapping the data from db to the array of type IOrganizationAdmin or null
  arrAdmin.forEach((element: IOrganizationAdmin | null, index) => {
    if (element) {
      arrUserList.push({
        key: index.toString(),
        name: `${element.title} ${element.fname} ${element.lname}`,
        title: element.title,
        fullname: element.fullname,
        fname: element.fname,
        lname: element.lname,
        jobTitle: element.jobTitle,
        phone: element.phone,
        description: element.description,
        companyHaedquaterAddress: element.companyHaedquaterAddress,
        level: null,
        email: element.email ?? '',
        companyName: element.partnerCompanyName ?? '',
        role: null,
        status: String(element.noOfuser ?? ''),
        progress: calculateProgress(
          Boolean(element.isTxAndCsAccepted),
          Boolean(element.isApplicationReview),
          Boolean(element.isApplicationApproved)
        ),
        icon: undefined,
        isLocked: element.isLocked,
        module: String(element.noOfModule ?? ''),
        buttonStatus: 'Access',
        moduleList: element.moduleList,
        domains: element.domains,
        presetName: element?.presetName
      });
    }
  });
  return arrUserList;
};
// Calculating the progress of the application dynamically
export const calculateProgress = (
  isTxAndCsAccepted: boolean,
  isApplicationReview: boolean,
  isApplicationApproved: boolean
): string => {
  if (isTxAndCsAccepted && isApplicationReview && isApplicationApproved) {
    return '100%';
  } else if (isTxAndCsAccepted && isApplicationReview) {
    return '75%';
  } else if (isTxAndCsAccepted) {
    return '50%';
  } else {
    return '25%';
  }
};
