import { useState, useEffect, useContext } from 'react';
import OrgUser from '../OrgUser/OrgUser'; // Import the OrgUser class
import OrganizationUser from '../OrganizationUser/OrganizationUser'; // Import the OrganizationUser class
import axios from 'axios';
import { InitialFlag } from '../Helper/enums';
import { LoginContext } from '../../context/IppContext';

export const getInitials = (name: string | null): string | undefined => {
  if (name === null) {
    return undefined;
  }

  const words = name?.split(' ');
  let initials = words.map((word) => word[0]?.toUpperCase()).join('');

  // If there are more than two initials, take the first and last initials
  if (words.length > 2) {
    initials = words[0][0]?.toUpperCase() + words[words.length - 1][0]?.toUpperCase();
  }
  return initials;
};

export const useUserAndAdminInitials = (userEmail: string, dataFlag: string) => {
  const { setTokenExpired } = useContext(LoginContext);
  const [initials, setInitials] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
  const jwtToken = sessionStorage.getItem('jwtToken');
  const preferredRole = sessionStorage.getItem('preferredRole');
  const apiKey = String(process.env.REACT_APP_API_KEY ?? '');

  useEffect(() => {
    // Fetch user, admin, or Bt admin details based on the dataFlag
    const fetchData = async () => {
      try {
        if (dataFlag === InitialFlag.User) {
          const userObj = new OrgUser({ email: userEmail ?? '' });
          const userData = await userObj.getUserDetails(setTokenExpired);
          if (userData.body) {
            setInitials(userData.body.name);
          }
        } else if (dataFlag === InitialFlag.Admin) {
          const organizationAdminObj = new OrganizationUser();
          const { fullName } = await organizationAdminObj.getAllAdminUser(setTokenExpired);
          setInitials(fullName);
        } else if (dataFlag === InitialFlag.BtAdmin) {
          const config = {
            headers: {
              Authorization: jwtToken,
              'x-api-key': apiKey,
              'Role-Name': preferredRole
            }
          };
          const url = `${backEndServerUrl}/organization/btadmin/${userEmail}`;
          const response = await axios.get(url, config);
          const { fname, lname } = response.data;
          const fullName = fname + ' ' + lname;
          setInitials(fullName);
          setFirstName(fname);
        }
      } catch (e) {
        console.error(e);
        setInitials('');
        setFirstName('');
      }
    };

    void fetchData();
  }, [userEmail, dataFlag]);

  // Calculate initials based on the fetched data
  const calculatedInitials = getInitials(initials) as string;

  const getFullName = initials;
  const getFirstName = firstName;

  return { calculatedInitials, getFullName, getFirstName };
};
