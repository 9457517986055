import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoLogoutPopup from './AutoLogoutMessage';
import { LoginContext } from '../../context/IppContext';
import { routes } from '../../routes/Routes';

interface SessionTimeoutProps {
  timeoutInMinutes: number;
  onSessionTimeout: () => void;
  children: any;
}
// Here session timeout component manages the state of the session timer and renders a countdown timer or a redirect to the login page when the session expires.
const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  timeoutInMinutes,
  onSessionTimeout,
  children
}) => {
  const { tokenExpired, setTokenExpired } = useContext(LoginContext);
  const [sessionExpired, setSessionExpired] = useState(false);
  const sessionStartTime = useRef<number>(Date.now());

  const updateStartTime = () => {
    sessionStartTime.current = Date.now();
  };

  // This hook sets a timeout to check for session expiry after a certain time and also adds event listeners to increase session expiry on every user intraction with the application
  // Cleanup function removes all eventlisteners and timeout.
  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const registerEventListeners = () => {
      events.forEach((event) => document.addEventListener(event, updateStartTime));
    };
    const clearEventListeners = () => {
      events.forEach((event) => document.removeEventListener(event, updateStartTime));
    };

    let timerId: any;
    const timeoutInMs = timeoutInMinutes * 60 * 1000;
    const checkSessionTimeout = () => {
      const elapsedTime = Date.now() - sessionStartTime.current;
      if (elapsedTime >= timeoutInMs) {
        setSessionExpired(true);
        clearEventListeners();
        clearTimeout(timerId);
        onSessionTimeout();
      } else {
        // Reset timout to check session expiry.
        timerId = setTimeout(checkSessionTimeout, timeoutInMs - elapsedTime);
      }
    };

    registerEventListeners();
    // Initial timeout to check session expiry.
    timerId = setTimeout(
      checkSessionTimeout,
      timeoutInMs - (Date.now() - sessionStartTime.current)
    );
    return () => {
      clearEventListeners();
      clearTimeout(timerId);
    };
  }, [timeoutInMinutes, onSessionTimeout]);

  // State to control the visibility of the auto-logout popup.
  const [showAutoLogoutPopup, setShowAutoLogoutPopup] = useState(true);
  const navigate = useNavigate();
  if (tokenExpired) {
    onSessionTimeout();
  }

  const handleAutoLogoutConfirm = () => {
    setShowAutoLogoutPopup(false);
    setTokenExpired(false);
    navigate(routes.login);
  };

  if (sessionExpired || tokenExpired) {
    return <>{showAutoLogoutPopup && <AutoLogoutPopup onConfirm={handleAutoLogoutConfirm} />}</>;
  }

  return <>{children}</>;
};

export default SessionTimeout;
