import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import PasswordChange from '../utils/PasswordChange/changePassword';
import PasswordChangeForm from '../components/passwordChange';
import PasswordChangeBtAdmin from '../utils/PasswordChange/changePasswordBtAdmin';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import { PasswordRules } from '../components/passwordRules';
import { changePassDisplayMessage } from '../utils/Helper/enums';
import { btAdminRoutes, routes } from '../routes/Routes';
interface changePasswordProps {
  userEmail: string | any;
}
// This component is responsible to to change the users password , user have to enter their verified email, old password and new password.
const ChangePassword: React.FC<changePasswordProps> = ({ userEmail }) => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const isBtAdmin = location.pathname.startsWith(btAdminRoutes.dashboard);

  const [codeStatus, setCodeStatus] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [otpError, setOtpError] = useState('');

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';
  const buttonText1 = confirmPasswordShown ? 'Hide' : 'Show';
  const buttonText2 = currentPasswordShown ? 'Hide' : 'Show';

  const isEmailEmpty = email.trim() === '';
  const isNewPassword = newPassword.trim() === '';
  const isCurrentPassword = currentPassword.trim() === '';
  const isconfirmPassword = confirmPassword.trim() === '';
  const isOtp = otp.trim().length !== 6;
  const disabledButton =
    !passwordError &&
    !confirmPasswordError &&
    !isEmailEmpty &&
    !isNewPassword &&
    !isCurrentPassword &&
    !isconfirmPassword &&
    !isOtp &&
    !otpError &&
    PasswordRules(newPassword).every((rule) => rule.isValid);

  const disabledButtonBtAdmin =
    !passwordError &&
    !confirmPasswordError &&
    !isEmailEmpty &&
    !isNewPassword &&
    !isCurrentPassword &&
    !isconfirmPassword &&
    PasswordRules(newPassword).every((rule) => rule.isValid);

  const formValid = isBtAdmin ? disabledButtonBtAdmin : disabledButton;

  useEffect(() => {
    // Validate password policy.
    if (newPassword) {
      if (newPassword.length < 16) {
        setPasswordError('Password should be at least 16 characters long');
      } else if (!/[A-Z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one uppercase letter');
      } else if (!/[a-z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one lowercase letter');
      } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)) {
        setPasswordError(
          'Password should contain at least one special character. Allowed special characters are: !@#$%^&*()_+-=[]{};:\'",.<>/?'
        );
      } else {
        setPasswordError('');
      }
    }
  }, [newPassword]);

  // Checks if new password and confirm password are same if not sets error
  const checkPassNotSame = (confirmPass: string, newPass: string): void => {
    if (confirmPass) {
      // Validate password confirmation
      if (confirmPass !== newPass) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
  };
  // checks if new password same as old password if true sets error
  const checkPassSameAsOld = (currentPass: string, newPass: string) => {
    if (newPass) {
      if (currentPass === newPass) {
        setPasswordError('New Password can not same as current password');
      } else {
        setPasswordError('');
      }
    }
  };

  // Method to set entered new password.
  const handlecurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    checkPassSameAsOld(value, newPassword);
    setCurrentPassword(value);
  };
  // Method to set entered new password.
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    checkPassNotSame(confirmPassword, value);
    checkPassSameAsOld(currentPassword, value);
    setNewPassword(value);
  };
  // Method to set entered confirm password.
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    checkPassNotSame(value, newPassword);
    setConfirmPassword(value);
  };
  // Method to set entered OTP.
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const otpValue = e.target.value;
    setOtp(otpValue);
    if (otpValue.length > 6) {
      setOtpError('OTP should be 6 digits only');
    } else {
      setOtpError('');
    }
  };

  const getResponseMessage = (statusCode: number, message: string): string => {
    switch (statusCode) {
      case 201:
        return changePassDisplayMessage.successMessage;
      case 400:
        return message;
      case 403:
        return message;
      case 404:
        return message;
      case 401:
        return isBtAdmin ? message : changePassDisplayMessage.userErrorMessage;
      case 410:
        return isBtAdmin ? changePassDisplayMessage.btAdminErrorMessage : message;
      case 409:
        return isBtAdmin ? changePassDisplayMessage.btAdminErrorMessage : message;
      default:
        return isBtAdmin
          ? changePassDisplayMessage.btAdminErrorMessage
          : changePassDisplayMessage.userErrorMessage;
    }
  };

  // Toggle password show and hide functions.
  const togglecurrentPassword = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const togglePassword = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const handleChangePassword = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!formValid) {
      return;
    }
    setShowAlert(true);
    setShow(!show);
    const changePasswordObj = new PasswordChange({
      email,
      currentPassword,
      newPassword,
      otp
    });
    const sendCodeStatus = await changePasswordObj.changePassword();
    setEmail(email);
    const responseMessage = getResponseMessage(sendCodeStatus.statusCode, sendCodeStatus.body);
    setResponseMessage(responseMessage);
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };
  const handleChangePasswordBtAdmin = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (!formValid) {
      return;
    }
    setShowAlert(true);
    setShow(!show);
    const changePasswordObj = new PasswordChangeBtAdmin({
      email,
      currentPassword,
      newPassword
    });
    const sendCodeStatus = await changePasswordObj.changePassword();
    setEmail(email);
    const responseMessage = getResponseMessage(sendCodeStatus.statusCode, sendCodeStatus.body);
    setResponseMessage(responseMessage);
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };

  const changePassword = isBtAdmin ? handleChangePasswordBtAdmin : handleChangePassword;

  // method to close popup.
  const popupCloseAlertHandler = (e: boolean): void => {
    setShowAlert(e);
    setShow(e);
    if (codeStatus) {
      navigate(routes.login);
    } else {
      navigate(0);
    }
  };
  // method to close popup.
  const popupCloseFormHandler = (e: boolean): void => {
    setShowForm(false);
    navigate(0);
  };

  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter' && formValid) {
      event.preventDefault();
      await handleChangePassword(event);
    }
  };

  return (
    <CustomPopup onClose={popupCloseFormHandler} show={showForm} width={'w-41%'}>
      <form onSubmit={wrapAsyncFunction(changePassword)}>
        <div className="w-72">
          <div className="text-[22px] text-center mb-[15px]">Change Password</div>
          <InputField
            id={'email'}
            className="text-light-gray h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
            type="email"
            value={email}
            onKeyUp={handleKeyUp}
            readOnly
          />
          <Link
            className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
            onClick={(e) => togglecurrentPassword(e)}
            to={''}
          >
            {buttonText2}
          </Link>
          <InputField
            id={'currentPassword'}
            className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
            type={currentPasswordShown ? 'text' : 'password'}
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={handlecurrentPasswordChange}
          />
          <PasswordChangeForm
            password={newPassword}
            confirmPassword={confirmPassword}
            onPasswordChange={handleNewPasswordChange}
            onConfirmPasswordChange={handleConfirmPasswordChange}
            passwordError={passwordError}
            confirmPasswordError={confirmPasswordError}
            passwordShown={passwordShown}
            togglePassword={togglePassword}
            confirmPasswordShown={confirmPasswordShown}
            toggleConfirmPassword={toggleConfirmPassword}
            formValid={formValid}
            buttonText={buttonText}
            buttonText1={buttonText1}
            otp={otp}
            otpError={otpError}
            onOtpChange={handleOtpChange}
            buttonName="Change Password"
          />
        </div>
      </form>
      <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
        <div className="max-h-30%">
          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
        </div>
      </CustomPopup>
    </CustomPopup>
  );
};
export default ChangePassword;
