import React, { useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import SubmitCode from '../components/SubmitCode';
import SendVerificationCode from '../utils/PasswordRsest/sendVerificationCode';
import PasswordChangeForm from '../components/passwordChange';
import ValidateCode from '../components/ValidateCode';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import AuthLayout from '../layout/AuthLayout';
import { IPPHeading } from '../library/Heading/Heading';

// This component is responsible for verifying user and setting up first time password for the user.
const PasswordSetup: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const [codeStatus, setCodeStatus] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isMessage, setIsMessage] = useState('User has successfully verified');
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [codeValid, setCodeValid] = useState(false);
  const [token, setToken] = useState('');
  const [isExpire, setIsExpire] = useState(true);
  const [newstatus, setNewstatus] = useState(false);

  useEffect(() => {
    const userParams = () => {
      const urlSearchParams = new URLSearchParams(location.search);
      const useremail = urlSearchParams.get('email');
      const expirationTime = urlSearchParams.get('expire');
      const code = urlSearchParams.get('code');
      setToken(code ?? '');
      setEmail(useremail ?? '');
      const currentTimestamp = Date.now() / 1000;
      const tokenExpirationTimestamp = Number(expirationTime);
      if (tokenExpirationTimestamp < currentTimestamp) {
        setIsExpire(false);
      }
      if (email === useremail && token === code) {
        setNewstatus(true);
      }
    };
    void userParams();
  }, [location.search, email, token, isExpire]);

  useEffect(() => {
    // Validate password policy.
    if (newPassword) {
      if (newPassword.length < 16) {
        setPasswordError('Password should be at least 16 characters long');
      } else if (!/[A-Z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one uppercase letter');
      } else if (!/[a-z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one lowercase letter');
      } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)) {
        setPasswordError(
          'Password should contain at least one special character. Allowed special characters are: !@#$%^&*()_+-=[]{};:\'",.<>/?'
        );
      } else {
        setPasswordError('');
      }
    }
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword) {
      // Validate password confirmation
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
  }, [confirmPassword, newPassword]);
  // Toggle password show and hide functions.
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  // Toggle confirm password show and hide functions.
  const toggleConfirmPassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';
  const buttonText1 = confirmPasswordShown ? 'Hide' : 'Show';
  // Method to set entered new password.
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };
  // Method to set entered confirm password.
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  // Call back functions to get the appropriate state value from validate.tsx component.
  const onSetCodeStatus = useCallback((codeValid: boolean) => {
    setCodeValid(codeValid);
  }, []);
  // ToDo -- will be removed/Uncomment this part based on Ivan review.
  // Call back functions to get the appropriate state value from validate.tsx component.
  // const onSetStatusCode = useCallback((code: number) => {
  //   setCode(code);
  //   if (code === 400) {
  //     setIsMessage('User is already verified, please set up your password');
  //   }
  // }, []);
  // storing user email to session storage for reseting password
  sessionStorage.setItem('userEmail', email);

  // Method to send verification code to user email.
  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);
    setShow(!show);
    const sendVerificationCodeObj = new SendVerificationCode({
      email
    });
    const sendCodeStatus = await sendVerificationCodeObj.sendCode();
    setEmail(email);
    switch (sendCodeStatus.statusCode) {
      case 201:
        setResponseMessage('Verification code has been sent to your email,please enter the code');
        break;
      case 400:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 404:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 429:
        setResponseMessage(sendCodeStatus.body);
        break;
      default:
        setResponseMessage('Failed to reset Password');
        break;
    }
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };
  // method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
    setResponseMessage('Please wait');
    if (!codeStatus) {
      navigate(0);
    }
  };
  // checking the form validity.
  const isEmailEmpty = email.trim() === '';
  const isNewPassword = newPassword.trim() === '';
  const isconfirmPassword = confirmPassword.trim() === '';
  const formValid =
    !passwordError &&
    !confirmPasswordError &&
    !isEmailEmpty &&
    !isNewPassword &&
    !isconfirmPassword;

  // method for enter key button. user can hit enter button from their keyboard to submit the form.
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && formValid) {
      event.preventDefault();
      await handleResetPassword(event);
    }
  };
  return (
    <AuthLayout>
      <div className="w-fit mx-auto z-[5] relative p-10">
        {!codeValid ? (
          <ValidateCode
            email={email}
            token={token}
            onSetCodeStatus={onSetCodeStatus}
            newstatus={newstatus}
            isExpire={isExpire}
          />
        ) : (
          <div className="block p-1 rounded-lg shadow-lg pointer-events-auto float-none justify-center bg-white border border-black max-w-sm">
            {!codeStatus && newstatus ? (
              <div className="p-4">
                <form onSubmit={wrapAsyncFunction(handleResetPassword)}>
                  <div className="w-full">
                    <div className="text-sm text-center mb-4">{isMessage}</div>
                    <IPPHeading className="text-xl text-center mb-3" headerText="Password Setup" />
                    <InputField
                      id={'PasswordSetUpEmail'}
                      className="text-light-gray h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                      type="email"
                      value={email}
                      onKeyUp={handleKeyUp}
                      readOnly
                    />
                    <PasswordChangeForm
                      password={newPassword}
                      confirmPassword={confirmPassword}
                      onPasswordChange={handleNewPasswordChange}
                      onConfirmPasswordChange={handleConfirmPasswordChange}
                      confirmPasswordError={confirmPasswordError}
                      passwordError={passwordError}
                      passwordShown={passwordShown}
                      togglePassword={togglePassword}
                      confirmPasswordShown={confirmPasswordShown}
                      toggleConfirmPassword={toggleConfirmPassword}
                      formValid={formValid}
                      buttonText={buttonText}
                      buttonText1={buttonText1}
                      buttonName="Reset Password"
                    />
                  </div>
                </form>
                <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
                  <div className="max-h-30%">
                    <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                  </div>
                </CustomPopup>
              </div>
            ) : (
              <div>
                <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
                  <div className="max-h-30%">
                    <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                  </div>
                </CustomPopup>
                <SubmitCode email={email} newPassword={newPassword} />
              </div>
            )}
          </div>
        )}
      </div>
    </AuthLayout>
  );
};
export default PasswordSetup;
