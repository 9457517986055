import React, { useState, Fragment, useEffect, useCallback, useId } from 'react';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import edit from '../../assets/img/edit.png';
import UpdateOrganization from '../../utils/OrgUser/UpdateOrgAdmin';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { JobTitle, Title, responseMessages } from '../../utils/Helper/enums';
import { wrapAsyncFunction } from '../UtilityFunction/wrapAsyncFunction';
import Button from '../../library/Button/button';
import { validateAddressPattern, validateNamePattern } from '../../utils/Helper/helper';
import IUserList from '../../utils/UserList/UserList.interface';

interface editAdminUserProps {
  partner: IUserList;
  onChange: (partner: IUserList) => void;
}
// Re-usable component for updating Organization users for a specific Organization Admin
export const EditAdminUserForm: React.FC<editAdminUserProps> = ({ partner, onChange }) => {
  // Interface for declaring data types
  interface formDetails {
    email?: string | null;
    fullname?: string | null;
    jobTitle?: string | null;
    phone?: string | null;
    partnerCompanyName?: string | null;
    companyHaedquaterAddress?: string | null;
    description?: string | null;
  }
  const optionTitle = [
    { label: Title.Mr, value: Title.Mr },
    { label: Title.Mrs, value: Title.Mrs },
    { label: Title.Miss, value: Title.Miss },
    { label: Title.Ms, value: Title.Ms },
    { label: Title.Dr, value: Title.Dr },
    { label: Title.Prof, value: Title.Prof },
    { label: Title.Sir, value: Title.Sir }
  ];

  const optionJobTitle = [
    { label: JobTitle.Developer, value: JobTitle.Developer },
    { label: JobTitle.Admin, value: JobTitle.Admin }
  ];

  // ToDO: In later stages, we're planning to handle it from the popup.
  const [showFormEdit, setShowFormEdit] = useState<boolean>(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [fullname, setFullname] = useState<string>(partner.fullname ?? '');
  const [jobTitle, setJobTitle] = useState<string>(partner.jobTitle ?? '');
  const [phone, setPhone] = useState<string>(partner.phone ?? '');
  const [companyHaedquaterAddress, setCompanyHeadQuarterAddress] = useState<string>(
    partner.companyHaedquaterAddress ?? ''
  );
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [description, setDescription] = useState<string>(partner.description ?? '');
  // Declaring state for handling errors.
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const uniqueId = useId();

  const resetFormState = () => {
    setFullname(partner.fullname ?? '');
    setJobTitle(partner.jobTitle ?? '');
    setPhone(partner.phone ?? '');
    setCompanyHeadQuarterAddress(partner.companyHaedquaterAddress ?? '');
    setDescription(partner.description ?? '');
    setShowAlert(false);
  };

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};

    const validationError = validateNamePattern(fullname);
    if (!fullname.trim()) {
      errors.fullname = 'Please provide a valid name';
    } else if (typeof validationError === 'string') {
      errors.fullname = validationError;
    }

    const addressValidationError = validateAddressPattern(companyHaedquaterAddress);
    if (!companyHaedquaterAddress.trim()) {
      errors.companyHaedquaterAddress = "Company address can't be empty";
    } else if (typeof addressValidationError === 'string') {
      errors.companyHaedquaterAddress = addressValidationError;
    }

    const descriptionValidationError = validateAddressPattern(description);
    if (!description.trim()) {
      errors.description = "Description can't be empty";
    } else if (typeof descriptionValidationError === 'string') {
      errors.description = descriptionValidationError;
    }
    setErrors(errors);
  };

  // Storing data from UI to db via promise
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    // Form submission code here
    const userObj = new UpdateOrganization({
      email: partner.email ?? '',
      fullname,
      jobTitle,
      phone,
      partnerCompanyName: partner.companyName ?? '',
      companyHaedquaterAddress,
      description
    });
    // API responses have been merged since actions are same
    const updateStatus = await userObj.updateOrgAdmin();
    if (updateStatus.statusCode === 201) {
      onChange({
        ...partner,
        fullname,
        jobTitle,
        phone,
        companyHaedquaterAddress,
        description
      });
    }

    const errorMessage = responseMessages.UserExists;
    const successMessage = responseMessages.UpdateUserSuccess;
    const response = handleApiResponse(updateStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowLoader(false);
    setShowAlert(true);
  };

  // Wrap the handleButtonClick function in useCallback to show the update user form on clicking the edit button
  const handleButtonClick = () => {
    setShowFormEdit((prevShowFormEdit) => !prevShowFormEdit);
    resetFormState();
  };

  // Wrap the popupCloseHandler function in useCallback to close the create user form on clicking the close button
  const popupCloseHandler = () => {
    setShowFormEdit(false);
  };

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [fullname, phone, companyHaedquaterAddress, description]);

  return (
    <Fragment>
      <Button
        id={`edit-organisation-${uniqueId}`}
        aria-label="Edit Organisation"
        onclick={handleButtonClick}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <ImageTag src={edit} width={'20px'} alt={''} name={'Edit'} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
       -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Edit Organisation
            </span>
          </div>
        )}
      </Button>
      {showFormEdit && (
        <CustomPopup onClose={popupCloseHandler} show={showFormEdit} width={'w-41%'}>
          <Form
            orgEmail={partner.email}
            userFname={fullname}
            setFname={setFullname}
            orgJobTitle={jobTitle}
            setJobTitle={setJobTitle}
            orgPhone={phone}
            setPhone={setPhone}
            orgPartnerCompanyName={partner.companyName}
            orgCompanyHaedquaterAddress={companyHaedquaterAddress}
            setCompanyHeadQuarterAddress={setCompanyHeadQuarterAddress}
            orgDescription={description}
            setDescription={setDescription}
            showEditForm={showAlert}
            setShowFormEdit={setShowFormEdit}
            message={responseMessage}
            showLoader={showLoader}
            errors={errors}
            onSubmit={wrapAsyncFunction(onSubmit)}
            optionTitle={optionTitle}
            optionJobTitle={optionJobTitle}
            showFormEdit={showFormEdit}
            isBtAdmin={false}
            usersEmail={''}
            isFeedback={false}
            createUser={function (e: React.FormEvent<Element>): void {
              throw new Error('Function not implemented.');
            }}
          />
        </CustomPopup>
      )}
    </Fragment>
  );
};
