import React, { useState } from 'react';
import CustomPopup from './CustomPopup';
import PartnerDomainList from '../register/PartnerDomainList';
import InputField from '../../library/inputField/inputField';
import UpdateOrganization from '../../utils/OrgUser/UpdateOrgAdmin';
import ConditionalLoader from '../ConditionalLoader';
import Button from '../../library/Button/button';
import { IPPHeading } from '../../library/Heading/Heading';
import { IAlertState } from '../../utils/Helper/type';
import { domainRegex } from '../../utils/regex';
import IUserList from '../../utils/UserList/UserList.interface';

interface OrgUpdateProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  emailList: string[];
  isLoading: boolean;
  partner: IUserList;
  onDomainChange: (domains: Array<string>) => void;
}

export const OrgUpdate: React.FC<OrgUpdateProps> = ({
  partner,
  show,
  setShow,
  emailList,
  isLoading,
  onDomainChange
}) => {
  const domains = partner.domains;
  const [alertState, setAlertState] = useState<IAlertState>({
    show: false,
    message: null
  });
  const [newDomain, setNewDomain] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  // Method to close the popup on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
  };

  const updateDomains = async (updatedDomains: Array<string>) => {
    const fullname = partner.fullname ?? '';
    const orgEmail = partner.email ?? '';
    const userObj = new UpdateOrganization({
      email: orgEmail,
      fullname,
      jobTitle: partner.jobTitle,
      partnerCompanyName: partner.companyName,
      companyHaedquaterAddress: partner.companyHaedquaterAddress,
      description: partner.description,
      domains: updatedDomains
    });
    // API responses have been merged since actions are same
    const updateStatus = await userObj.updateOrgAdmin();
    if (updateStatus.statusCode === 201) {
      onDomainChange(updatedDomains);
    }
  };

  const checkWhitespace = (str: string) => {
    return /\s/.test(str);
  };

  const customAlert = (message: string) => {
    setAlertState({
      show: true,
      message
    });
  };

  const closeAlert = () => {
    setAlertState({
      show: false,
      message: null
    });
  };

  const handleAddDomain = async () => {
    setIsUpdating(true);
    const domain = newDomain.trim().toLowerCase();
    if (domains.includes(domain)) {
      customAlert('Sorry, this domain already exists!!');
    } else if (checkWhitespace(domain)) {
      customAlert("Sorry, you can't use whitespace!!");
    } else if (!domainRegex.test(domain)) {
      customAlert('Sorry, this is not a valid domain.');
    } else {
      await updateDomains([...domains, domain]);
      domains.push(domain);
      setNewDomain('');
    }
    setIsUpdating(false);
  };

  return (
    <CustomPopup onClose={popupCloseHandler} show={show} width={'min-w-fit'}>
      <div className="w-fit h-fit">
        <div className="flex start justify-between px-4 py-4 border-b border-black">
          <h4 className="mb-0 leading-normal text-2xl text-black">{partner?.companyName}</h4>
        </div>
        <div className="flex flex-row justify-between m-2.5">
          <InputField
            id={'domain-add'}
            type="text"
            placeholder={'Enter your domain'}
            className="border-black p-[4.8px 4.8px] rounded-lg pl-[6px] border border-light-gray"
            name="s"
            value={newDomain}
            onChange={(event) => {
              event.preventDefault();
              setNewDomain(event.target.value);
            }}
          />
          <Button
            className="px-4 py-[1.75] bg-offwhite hover:bg-black disabled:bg-offwhite disabled:hover:bg-offwhite disabled:hover:cursor-not-allowed disabled:hover:text-[#2c323f] hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
            disabled={newDomain === '' || isUpdating}
            loading={isUpdating}
            onclick={handleAddDomain}
            loadingColour="black"
            buttonText="Add"
          />
        </div>
        <div className="grow shrink basis-auto m-2.5 block max-h-[30rem]">
          <ConditionalLoader
            isLoading={isLoading}
            className="flex justify-center max-w-[250px]"
            width={250}
          >
            {domains?.length > 0 && emailList?.length > 0 ? (
              <PartnerDomainList
                domains={domains}
                emailList={emailList}
                onDomainDelete={updateDomains}
                isLoading={isUpdating}
              />
            ) : (
              <div className="px-2 py-2 pt-4 text-center font-bold">
                <p className="text-bold">Domain list is empty.</p>
              </div>
            )}
          </ConditionalLoader>
        </div>
      </div>
      <CustomPopup show={alertState.show} onClose={closeAlert} width={'w-41%'}>
        <div>
          <IPPHeading
            className="text-lg pb-4 whitespace-pre-wrap"
            headerText={alertState.message ?? ''}
          />
        </div>
      </CustomPopup>
    </CustomPopup>
  );
};

export default OrgUpdate;
