import axios, { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import IOrgUser from './OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import IUserList from '../UserList/UserList.interface';
import IOrganizationUser from '../OrganizationUser/OrganizationUser.interface';
import { adminUserList } from '../OrganizationUser/OrganizationUser.helper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class OrgUser {
  public apiResponse: APIResponse | undefined;
  public jwtToken = sessionStorage.getItem('jwtToken');
  public apiKey = String(process.env.REACT_APP_API_KEY ?? '');
  public config = {
    headers: {
      Authorization: this.jwtToken,
      'x-api-key': this.apiKey
    }
  };

  // Declaring the constructor to get the request body. This constructor is of type IOrgUser
  constructor(public userInfo: IOrgUser) {}

  // Declaring the sub-path
  public subPath: string = '/organization/user';

  // POST request for creating Organization Users and storing it to db. This methods returns a response body along with a status code.
  public async getAllOrgUser(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      switch (error?.response?.status) {
        case 406:
          this.apiResponse = {
            statusCode: 406,
            body: 'User already exists in the Database'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  public async getUserDetails(setTokenExpired: Function): Promise<APIResponse> {
    const email = this.userInfo.email;
    const userDetailsPath = this.subPath + '/' + email;
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(userDetailsPath, 'GET');
      const url: string = this.createUrl(userDetailsPath);
      const response: AxiosResponse<any, any> = await axiosWithRoles.get(url);
      if (!response.data.email && !response.data.orgName) {
        setTokenExpired(true);
      }
      const extractedFname = response.data.name;
      sessionStorage.setItem('userName', extractedFname);
      this.apiResponse = { statusCode: response.status, body: response?.data };
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
        setTokenExpired(true);
        this.apiResponse = { statusCode: 403, body: null, error: JSON.stringify(error) };
      }
      switch (error?.response?.status) {
        case 406:
          this.apiResponse = {
            statusCode: 406,
            body: null,
            error: 'No data found'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: null, error: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  public async getAllUser(setTokenExpired: Function): Promise<Array<IUserList | null>> {
    const userDetailsPath = this.subPath;
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(userDetailsPath, 'GET');
      const url: string = this.createUrl(userDetailsPath);
      const response: AxiosResponse<any, any> = await axios.get(url, this.config);
      const arrUsers: Array<IOrganizationUser | null> = [];
      if (Array.isArray(response.data)) {
        response.data.forEach((element: IOrganizationUser) => {
          arrUsers.push(element);
        });
      }
      return adminUserList(arrUsers);
      // this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      console.error(error);
      if (error.code === 'ERR_NETWORK') {
        setTokenExpired(true);
        return [];
      }
    }
    return await this.getAllUser(setTokenExpired);
  }
  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
