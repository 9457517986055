import axios, { AxiosResponse } from 'axios';
import IUserList from '../UserList/UserList.interface';
import { adminUserList } from './OrganizationUser.helper';
import IOrganizationUser from './OrganizationUser.interface';
import { NavigateFunction } from 'react-router-dom';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class OrganizationAdmin {
  // Storing the user's email via session storage
  public userEmail = sessionStorage.getItem('userEmail');

  // Declaring the sub-path
  public subPath: string = `/organization/admin/${this.userEmail}`;
  public jwtToken = sessionStorage.getItem('jwtToken');
  public apiKey = String(process.env.REACT_APP_API_KEY ?? '');
  // creating header to wrap during the api call.
  public config = {
    headers: {
      Authorization: this.jwtToken,
      'x-api-key': this.apiKey
    }
  };

  // GET request for fetching data from db to Organization User. This method is an array of type IUserList or null
  public async getAllAdminUser(
    setTokenExpired: Function
  ): Promise<{ fullName: string; users: Array<IUserList | null> }> {
    try {
      const url: string = this.createUrl(this.subPath);
      const response: AxiosResponse<any, any> = await axios.get(url, this.config);
      const { orgAdmin } = response.data;
      if (!orgAdmin.email && !orgAdmin.partnerCompanyName) {
        setTokenExpired(true);
      }
      const extractedFname = orgAdmin?.fname || '';
      const extractedLname = orgAdmin?.lname || '';
      const fullName = extractedFname + ' ' + extractedLname;
      sessionStorage.setItem('adminFullName', fullName);
      sessionStorage.setItem('adminFirstName', extractedFname);
      const companyName = orgAdmin?.partnerCompanyName || '';
      sessionStorage.setItem('companyName', companyName);
      sessionStorage.setItem('domainList', JSON.stringify(orgAdmin?.domains));
      const arrAdmin: Array<IOrganizationUser | null> = [];
      response.data.orgUser.forEach((element: IOrganizationUser) => {
        arrAdmin.push(element);
      });
      return {
        fullName,
        users: adminUserList(arrAdmin)
      };
    } catch (error: any) {
      console.error(error);
      if (error.code === 'ERR_NETWORK') {
        setTokenExpired(true);
        return {
          fullName: '',
          users: []
        };
      }
    }
    return await this.getAllAdminUser(setTokenExpired);
  }

  public async getAllAdminUserModule(
    setTokenExpired: (valyue: boolean) => void
  ): Promise<Array<IUserList | null>> {
    try {
      const url: string = this.createUrl(this.subPath);
      const response: AxiosResponse<any, any> = await axios.get(url, this.config);
      if (!response.data.orgAdmin.email && !response.data.orgAdmin.partnerCompanyName) {
        setTokenExpired(true);
      }
      const arrAdmin: Array<IOrganizationUser | null> = [];

      // Store orgAdmin data in the array
      arrAdmin.push(response.data.orgAdmin);

      // Store orgUser data in the array
      response.data.orgUser.forEach((element: IOrganizationUser) => {
        arrAdmin.push(element);
      });

      return adminUserList(arrAdmin);
    } catch (error: any) {
      console.error(error);
      if (error.code === 'ERR_NETWORK') {
        setTokenExpired(true);
        return [];
      }
    }
    return await this.getAllAdminUserModule(setTokenExpired);
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
